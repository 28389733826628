import React, { Component } from 'react';

class LoadingPage extends Component{
  constructor(){
    super();
    
    this.state = {
      inputFocus: false,
    }

    // this.inputFocusIn = this.inputFocusIn.bind(this);
    // this.inputFocusOut = this.inputFocusOut.bind(this);
  }

  componentDidMount(){
    // moment.locale('id');
  }

  componentDidUpdate(prevProps, prevState){
    // if(this.state.email !== prevState.email){
    //   if(this.state.email !== ''){
    //     this.setState({emailValid: true});
    //   }else{
    //     this.setState({emailValid: false});
    //   }
    // }
  }

  componentWillUnmount(){}

  // inputChange(event){
  //   this.setState({[event.target.name]: event.target.value});
  // }

  render(){
    return(
        <div className={`loading ${this.props.section ? 'section' : ''} ${this.props.field ? 'field' : ''}`}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 126.71 28.29">
            <path d="M119.65,8.18A24.51,24.51,0,0,0,111,9.38a.52.52,0,0,0-.28.51V12.4c0,.19.14.25.34.24h.23c2-.13,6.1-.38,7.64-.38s2.6.32,2.61,2.35h0v7c-2.16,1.77-6.32,3.35-6.32-.54V16.57c0-.6-.53-.54-.94-.5l-.52.08c-2.66.69-3.68,2.81-3.68,5.12v1.84c0,2.52,1.76,5.07,5.51,5.17a6.18,6.18,0,0,0,.86,0,8.06,8.06,0,0,0,3.16-.8l.06,0a14.18,14.18,0,0,0,2-1.24l.44.86c.63,1.24,1.36,1,2.77,1H126a.68.68,0,0,0,.69-.68V14.46C126.71,9.62,123.85,8.16,119.65,8.18Z" fill="currentcolor" />
            <path d="M11.66,19.84c0,.6.53.55.94.5,2.73-.32,4.2-1.78,4.2-4.83,0-6.48-2.62-7.12-8.27-7.12C2.34,8.39,0,9.82,0,18.21c0,7.16,1.47,10,6.43,10,2.85,0,6.7,0,9.44-.84l.23-.09a.52.52,0,0,0,.33-.54V24.05a.25.25,0,0,0-.26-.27h-.12l-.12,0h-.07a41.52,41.52,0,0,1-6.6.31c-2.78,0-4.11-.21-4.11-3.72a49.3,49.3,0,0,1,.08-5.57c.28-2.4,1.36-2.51,3.6-2.51,2.09,0,2.66.33,2.79,1.91.1,1.23.06,3.27,0,4.9Z" fill="currentcolor" />
            <path d="M31.1,8.54H27.23V4c0-.4-.28-.59-.63-.29l-.16.14-5.9,5.26c1.16.93,1.54,2.64,1.54,4.54v7.48c0,5.16,1.9,7,4.79,7h3.91c.37,0,.57-.16.57-.69V25.66a.8.8,0,0,0-.59-.9c-.67-.2-1.2-.39-1.86-.63-1-.37-1.67-1-1.67-3.85V12.47H31.1a.5.5,0,0,0,.5-.5V9A.51.51,0,0,0,31.1,8.54Z" fill="currentcolor" />
            <path d="M47.72,8.21c-3,0-4.6,1.24-6.22,2.4V.43c0-.4-.28-.58-.63-.28l-.16.14L36.38,4.14V27.57a.5.5,0,0,0,.49.5H41a.5.5,0,0,0,.5-.5V15.4c1.9-1.93,6.87-4.2,6.87,0V27.57a.5.5,0,0,0,.49.5h4.16a.5.5,0,0,0,.5-.5V15.05C53.55,11.33,52.26,8.21,47.72,8.21Z" fill="currentcolor" />
            <path d="M70.55,8.24H69.46a7.9,7.9,0,0,0-3.3.8l0,0a16.67,16.67,0,0,0-2.43,1.55l0-2.13v0c0-.4-.29-.59-.64-.29l-.16.14L58.54,12.2V27.58a.49.49,0,0,0,.49.49h4.16a.49.49,0,0,0,.49-.49V15.41c1.9-1.94,6.88-4.22,6.88,0V27.58a.49.49,0,0,0,.49.49h4.16a.49.49,0,0,0,.49-.49V15.05C75.7,11.52,74.54,8.54,70.55,8.24Z" fill="currentcolor" />
            <path d="M85.27,7.88,85.11,8,80.76,11.9V27.57a.49.49,0,0,0,.49.49h4.17a.49.49,0,0,0,.49-.49V8.17C85.91,7.76,85.61,7.59,85.27,7.88Z" fill="currentcolor" />
            <path d="M105.84,23.81h-.25c-1.16.1-3.9.3-6.48.3s-3.48-.73-3.48-5.74c0-5.78,1.12-5.92,3.48-5.92,2.65,0,5.47.15,6.44.2.15,0,.25-.08.25-.23V9.78a.43.43,0,0,0-.31-.38l-.23-.1a33.48,33.48,0,0,0-8.34-1c-3.75,0-6.44,1.88-6.44,9.93s2.8,10,6.44,10c2.85,0,5.94.17,8.67-1h0l.17-.06.16-.08a.52.52,0,0,0,.24-.41V24C106.16,23.87,106,23.8,105.84,23.81Z" fill="currentcolor" />
          </svg>
          {this.props.loadingText ? this.props.loadingText : 'Please Wait...'}
        </div>
    );
  }
}

export default LoadingPage;