import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
// import { getAnalytics } from 'firebase/analytics';

const firebaseApp = initializeApp({
    apiKey: "AIzaSyDMnjvYtTz64xEA3K2GHys1wUb_8Uyd24E",
    authDomain: "ethnica-admin.firebaseapp.com",
    // databaseURL: "https://ncig-tradein-default-rtdb.firebaseio.com",
    projectId: "ethnica-admin",
    storageBucket: "ethnica-admin.appspot.com",
    messagingSenderId: "733386906744",
    appId: "1:733386906744:web:18d44d7ab114df4f84a18d"
    // measurementId: "G-FD8GQFC7JL"
});

// getAnalytics(firebaseApp);

export const db = getFirestore(firebaseApp);
export const auth = getAuth(firebaseApp);
export const storage = getStorage(firebaseApp);

export default firebaseApp;