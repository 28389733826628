import React, { Component } from 'react';

class Button extends Component{
  constructor(){
    super();
    
    this.state = {
      // inputFocus: false
    }
    
    // this.inputFocusIn = this.inputFocusIn.bind(this);
  }

  componentDidMount(){
    // moment.locale('id');
  }

  componentDidUpdate(prevProps, prevState){
    // if(this.state.email !== prevState.email){
    //   if(this.state.email !== ''){
    //     this.setState({emailValid: true});
    //   }else{
    //     this.setState({emailValid: false});
    //   }
    // }
  }

  componentWillUnmount(){}

  // inputFocusIn(event){
  //   this.setState({inputFocus: true});
  // }

  render(){
    return(
      <button
        type={this.props.type ? this.props.type : 'button'}
        disabled={this.props.disabled}
        onClick={this.props.onClick}
        data-index={this.props.index}
        className={this.props.className}
      >
        {this.props.loading ? 'Processing...' : this.props.text}
      </button>
    );
  }
}

export default Button;