import React, { Component, Suspense } from 'react';
import { Routes, Route, Navigate, Link, NavLink } from 'react-router-dom';
import makeCancellablePromise from 'make-cancellable-promise';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { toast } from 'react-toastify';
import withRouter from './../helper/withrouter';
import { auth } from './../helper/firebase';
import LoadingPage from './../components/LoadingPage/LoadingPage';
import { ReactComponent as Logo } from './../assets/img/logo.svg';
import Button from './../components/Button/Button';
import './App.scss';
import 'reactjs-popup/dist/index.css';
import 'react-quill/dist/quill.snow.css';

// const Login = React.lazy(() => import('./Login/Login'));
const Login = React.lazy(() => {
        return new Promise(resolve => {
          setTimeout(() => resolve(import('./Login/Login')), 3000);
        });
      }),
      Dashboard = React.lazy(() => import('./Dashboard/Dashboard')),
      Soho = React.lazy(() => import('./Soho/Soho')),
      Page = React.lazy(() => import('./Page/Page')),
      PageForm = React.lazy(() => import('./Page/PageForm/PageForm')),
      WorkContent = React.lazy(() => import('./WorkContent/WorkContent')),
      WorkContentForm = React.lazy(() => import('./WorkContent/WorkContentForm/WorkContentForm')),
      WorkTag = React.lazy(() => import('./WorkTag/WorkTag')),
      WorkTagForm = React.lazy(() => import('./WorkTag/WorkTagForm/WorkTagForm')),
      JournalContent = React.lazy(() => import('./JournalContent/JournalContent')),
      JournalContentForm = React.lazy(() => import('./JournalContent/JournalContentForm/JournalContentForm')),
      JournalCategory = React.lazy(() => import('./JournalCategory/JournalCategory')),
      JournalCategoryForm = React.lazy(() => import('./JournalCategory/JournalCategoryForm/JournalCategoryForm')),
      Product = React.lazy(() => import('./Product/Product')),
      ProductForm = React.lazy(() => import('./Product/ProductForm/ProductForm')),
      General = React.lazy(() => import('./General/General'));

class App extends Component{
  constructor(){
    super();
    
    this.state = {
      signIn: '',
      email: '',
      nav: {
        navWork: false,
        navJournal: false
      }
    }

    this.appConfig = {
      safeMode: false
    };

    this.cancellableLogout = '';

    this.navToggle = this.navToggle.bind(this);
    this.signOut = this.signOut.bind(this);
  }

  componentDidMount(){
    onAuthStateChanged(auth, (user) => {
      if(user){
        this.setState({
          signIn: true,
          email: user.email
        });
        toast.success(() => (<>Sign In Successfully<span>Hi <strong>{user.email}</strong>!</span></>));
      }else{
        this.setState({
          signIn: false,
          email: ''
        });
      }
    });
  }

  componentDidUpdate(prevProps, prevState){}

  componentWillUnmount(){
    if(this.cancellableLogout){
      this.cancellableLogout.cancel();
    }
  }

  navToggle(event, open){
    let theNav = this.state.nav;

    if(open){
      theNav[event.target.name] = true;
    }else{
      theNav[event.target.name] = !theNav[event.target.name];
    }

    this.setState({ nav: theNav });
  }

  signOut(){
    this.cancellableLogout = makeCancellablePromise(signOut(auth));

    this.cancellableLogout.promise.then(() => {
      // console.log(userCredential);
      toast.success(() => (<>Sign Out Successfully<span>Bye!</span></>));
    }).catch((error) => {
      console.error(error);
      toast.error(() => (<>{error.code}<span>{error.message}</span></>));
    });
  }

  render(){
    if(this.state.signIn === ''){
      return(<LoadingPage />);
    }else{
      return(
        <>
          <div className="pad-top"></div>
          
          <ul className={`floating admin-lay ${this.state.signIn ? 'logged' : ''}`}>
            <li>
                <Link to="/" className="logo">
                  <Logo />
                  <span>Administrator</span>
                  <div>{this.state.email}</div>
                </Link>

                <ul className="nav">
                  <li>
                    <NavLink to="/dashboard" className={this.props.location.pathname === '/dashboard' ? 'is-active' : ''}>
                      Dashboard
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/page" className={this.props.location.pathname === '/page' ? 'is-active' : ''}>
                      Page
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/soho" className={this.props.location.pathname === '/soho' ? 'is-active' : ''}>
                      SoHo
                    </NavLink>
                  </li>
                  <li>
                    <button type="button" onClick={this.navToggle} name="navWork" className={this.state.nav.navWork ? 'is-active' : ''}>
                      Work<div></div>
                    </button>
                    <ul className={this.state.nav.navWork ? 'is-active' : ''}>
                      <li>
                        <NavLink to="/work/content" className={this.props.location.pathname.includes('/work/content') ? 'is-active' : ''}>
                          Content
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/work/tag" className={this.props.location.pathname.includes('/work/tag') ? 'is-active' : ''}>
                          Tag
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <button type="button" onClick={this.navToggle} name="navJournal" className={this.state.nav.navJournal ? 'is-active' : ''}>
                      Journal<div></div>
                    </button>
                    <ul className={this.state.nav.navJournal ? 'is-active' : ''}>
                      <li>
                        <NavLink to="/journal/content" className={this.props.location.pathname === '/journal/content' ? 'is-active' : ''}>
                          Content
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/journal/category" className={this.props.location.pathname === '/journal/category' ? 'is-active' : ''}>
                          Category
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <NavLink to="/product" className={this.props.location.pathname.includes('/product') ? 'is-active' : ''}>
                      Product
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/general" className={this.props.location.pathname === '/general' ? 'is-active' : ''}>
                      General
                    </NavLink>
                  </li>
                </ul>

                <div className="copy">
                  <ul className="floating forming">
                    <li>
                      <Button text="Sign Out" onClick={this.signOut} />
                    </li>
                  </ul>
                  Copyrights &copy; 2022 - {(new Date()).getFullYear()} <Link to="/">Ethnica Administrator</Link>.
                  <span>All rights reserved.</span>
                </div>
            </li>
            <li>
              <Suspense fallback={
                <LoadingPage />
              }>
                <Routes>
                  {/* <Route path="/admin/*" element={<Admin />} /> */}
                  <Route path="/login" element={
                    this.state.signIn ? (<Navigate to="/dashboard" />) : (<Login />)
                  } />
                  <Route path="/dashboard" element={
                    this.state.signIn ? (<Dashboard />) : (<Navigate to="/login" />)
                  } />
                  {/* Soho */}
                  <Route path="/soho" element={
                    this.state.signIn ? (<Soho appConfig={this.appConfig} />) : (<Navigate to="/login" />)
                  } />
                  {/* Page */}
                  <Route path="/page" element={
                    this.state.signIn ? (<Page />) : (<Navigate to="/login" />)
                  } />
                  <Route path="/page/add" element={
                    this.state.signIn ? (<PageForm />) : (<Navigate to="/login" />)
                  } />
                  <Route path="/page/edit/:idPage" element={
                    this.state.signIn ? (<PageForm />) : (<Navigate to="/login" />)
                  } />
                  {/* Work */}
                  <Route path="/work" element={
                    this.state.signIn ? (<Navigate to="/work/content" />) : (<Navigate to="/login" />)
                  } />
                  <Route path="/work/content" element={
                    this.state.signIn ? (<WorkContent navToggle={this.navToggle} appConfig={this.appConfig} />) : (<Navigate to="/login" />)
                  } />
                  <Route path="/work/content/add" element={
                    this.state.signIn ? (<WorkContentForm navToggle={this.navToggle} />) : (<Navigate to="/login" />)
                  } />
                  <Route path="/work/content/edit/:idWork" element={
                    this.state.signIn ? (<WorkContentForm navToggle={this.navToggle} />) : (<Navigate to="/login" />)
                  } />
                  <Route path="/work/tag" element={
                    this.state.signIn ? (<WorkTag navToggle={this.navToggle} appConfig={this.appConfig} />) : (<Navigate to="/login" />)
                  } />
                  <Route path="/work/tag/add" element={
                    this.state.signIn ? (<WorkTagForm navToggle={this.navToggle} />) : (<Navigate to="/login" />)
                  } />
                  <Route path="/work/tag/edit/:idTag" element={
                    this.state.signIn ? (<WorkTagForm navToggle={this.navToggle} />) : (<Navigate to="/login" />)
                  } />
                  {/* Journal */}
                  <Route path="/journal" element={
                    this.state.signIn ? (<Navigate to="/journal/content" />) : (<Navigate to="/login" />)
                  } />
                  <Route path="/journal/content" element={
                    this.state.signIn ? (<JournalContent navToggle={this.navToggle} appConfig={this.appConfig} />) : (<Navigate to="/login" />)
                  } />
                  <Route path="/journal/content/add" element={
                    this.state.signIn ? (<JournalContentForm navToggle={this.navToggle} />) : (<Navigate to="/login" />)
                  } />
                  <Route path="/journal/content/edit/:idJournal" element={
                    this.state.signIn ? (<JournalContentForm navToggle={this.navToggle} />) : (<Navigate to="/login" />)
                  } />
                  <Route path="/journal/category" element={
                    this.state.signIn ? (<JournalCategory navToggle={this.navToggle} appConfig={this.appConfig} />) : (<Navigate to="/login" />)
                  } />
                  <Route path="/journal/category/add" element={
                    this.state.signIn ? (<JournalCategoryForm navToggle={this.navToggle} />) : (<Navigate to="/login" />)
                  } />
                  <Route path="/journal/category/edit/:idCategory" element={
                    this.state.signIn ? (<JournalCategoryForm navToggle={this.navToggle} />) : (<Navigate to="/login" />)
                  } />
                  {/* Product */}
                  <Route path="/product" element={
                    this.state.signIn ? (<Product />) : (<Navigate to="/login" />)
                  } />
                  <Route path="/product/add" element={
                    this.state.signIn ? (<ProductForm />) : (<Navigate to="/login" />)
                  } />
                  <Route path="/product/edit/:idProduct" element={
                    this.state.signIn ? (<ProductForm />) : (<Navigate to="/login" />)
                  } />
                  {/* General */}
                  <Route path="/general" element={
                    this.state.signIn ? (<General />) : (<Navigate to="/login" />)
                  } />
                  {/* Global */}
                  <Route path="/" element={
                    <Navigate to={this.state.signIn ? '/dashboard' : '/login'} />
                  } />
                  <Route path="*" element={
                    this.state.signIn ? (<Navigate to="/dashboard" />) : (<Navigate to="/login" />)
                  } />
                </Routes>
              </Suspense>
            </li>
          </ul>
        </>
      );
    }
  }
}

export default withRouter(App);